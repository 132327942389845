<template>
  <li class="nav-item nav-search ml-md-n25">
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <div
        class="search-input-icon font-small-4 d-flex align-items-center"
        style="column-gap: .34rem"
      >
        <feather-icon
          icon="SearchIcon"
          size="20"
        />

        <p class="opacity-disabled d-none d-md-inline-flex">
          {{ `${$t('Search')}` }}
        </p>
      </div>
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon
          icon="SearchIcon"
          size="21"
        />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        :placeholder="`${$t('Search')} (ctrl + enter)`"
        autofocus
        autocomplete="off"
        @keyup.esc="showSearchBar = false; resetsearchQuery()"
      />
      <!--        @blur.stop="showSearchBar = false; resetsearchQuery()"-->

      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>
      <!-- Suggestions List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
        <b-tabs :class="{'show': searchQuery}">
          <b-tab
            v-for="(item, grp_index) in permittedSearchData"
            :key="`suggestion_${grp_index}`"
          >
            <template #title>
              <div class="p-75">
                <sw-icon :icon="item.icon"
                         class="mr-25"
                />
                {{ $t(`${title(item.key)}`) }}
              </div>
            </template>
            <ul>
              <li
                v-for="(suggestion, index) in item.data"
                :key="index"
                class="suggestion-group-suggestion cursor-pointer w-100"
                @mousedown.prevent="suggestionSelected(item.key, suggestion)"
              >
                <!-- Users, Contacts -->
                <b-link
                  v-if="item.key === 'users' || item.key === 'contacts' || item.key === 'applications'"
                  class="p-0 d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between"
                  style="column-gap: .5rem; row-gap: .34rem"
                >
                  <avatar
                    variant="light-primary"
                    :user="suggestion"
                  >
                    <template #subtitle>
                      <div
                        class="d-flex flex-wrap"
                        style="gap: .34rem"
                      >
                        <span v-if="suggestion.email">{{ suggestion.email }}</span>
                        <span v-if="suggestion.email">|</span>
                        <span v-if="suggestion.phone">{{ suggestion.phone | phoneFormat }}</span>
                      </div>
                    </template>
                  </avatar>

                  <div
                    class="d-flex flex-wrap"
                    style="column-gap: .5rem"
                  >
                    <b-badge v-if="item.key === 'applications' && suggestion.status === 'ACCEPTED'"
                             variant="light-success"
                    >
                      {{ $t('WasTaken') }}
                    </b-badge>
                    <b-badge v-else-if="item.key === 'applications' && suggestion.status === 'REJECTED'"
                             variant="light-danger"
                    >
                      {{ $t('AgreementStatuses.REJECTED') }}
                    </b-badge>
                    <b-badge
                      v-for="(field, i) in suggestion.contactAddonFields"
                      :key="i"
                      v-b-tooltip.hover.v-primary
                      variant="light-primary"
                      :title="field.contactAddonFieldType.name"
                    >
                      <template v-if="field.contactAddonFieldTypeOptions.length">
                        {{ field.contactAddonFieldTypeOptions.map(i => i.name).join(', ') }}
                      </template>

                      <template v-else-if="field.contactAddonFieldTextOptions.length">
                        {{ field.contactAddonFieldTextOptions.join(', ') }}
                      </template>

                      <template v-else>
                        <template
                          v-if="
                            field.value.toLowerCase() === 'true'
                              || field.value.toLowerCase() === 'false'"
                        >
                          {{ field.contactAddonFieldType.name }}
                        </template>

                        <template v-else>
                          {{ field.value }}
                        </template>
                      </template>
                    </b-badge>
                  </div>
                </b-link>

                <b-link
                  v-else-if="item.key === 'orders'"
                  class="p-0 d-flex w-100"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar variant="light-primary">
                      <feather-icon icon="ShoppingBagIcon" />
                    </b-avatar>
                    <div class="ml-50">
                      <div>{{ suggestion.number }}</div>
                      <small class="text-muted font-small-3">{{ getFullName(suggestion.contactThreadCartBox.contactThread.contact) }}</small>
                    </div>
                  </div>
                </b-link>

                <b-link
                  v-else-if="item.key === 'organizations'"
                  class="p-0 d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between"
                  style="row-gap: .5rem; column-gap: .34rem"
                >
                  <div
                    class="d-flex flex-column flex-md-row"
                    style="row-gap: .5rem; column-gap: 1rem"
                  >
                    <span class="text-primary">{{ suggestion.name }}</span>

                    <div
                      class="d-flex flex-column flex-md-row"
                      style="gap: .34rem"
                    >
                      {{ $t('Contacts') }}:

                      <b-avatar-group
                        v-if="suggestion.contacts.length"
                        size="sm"
                      >
                        <b-avatar
                          v-for="(contact, i) in suggestion.contacts.slice(0, 3)"
                          :key="i"
                          v-b-tooltip.v-primary
                          variant="light-primary"
                          :user="contact"
                          :title="`${contact.firstName} ${contact.lastName}`"
                        />
                      </b-avatar-group>

                      <span v-else>
                        <feather-icon icon="MinusIcon" />
                      </span>
                    </div>

                    <div
                      class="d-flex flex-column flex-md-row"
                      style="gap: .34rem"
                    >
                      {{ $t('Users') }}:

                      <b-avatar-group
                        v-if="suggestion.users.length"
                        size="sm"
                      >
                        <b-avatar
                          v-for="(user, i) in suggestion.users.slice(0, 3)"
                          :key="i"
                          v-b-tooltip.v-primary
                          variant="light-primary"
                          :user="user"
                          :title="`${user.firstName} ${user.lastName}`"
                        />
                      </b-avatar-group>

                      <span v-else>
                        <feather-icon icon="MinusIcon" />
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="suggestion.vatId"
                    class="d-flex flex-wrap"
                    style="column-gap: .5rem"
                  >
                    <b-badge
                      v-b-tooltip.hover.v-primary
                      variant="light-primary"
                      :title="$t('contact.vatId')"
                    >
                      {{ suggestion.vatId }}
                    </b-badge>
                  </div>
                </b-link>
              </li>

              <!-- Loading -->
              <li
                v-if="item.loading"
                class="d-flex justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  class="m-1"
                />
              </li>

              <!-- Empty content -->
              <li
                v-else-if="!item.data.length && searchQuery"
                class="suggestion-group-suggestion no-results"
              >
                <p>{{ $t('NoOptions') }}</p>
              </li>
            </ul>
          </b-tab>
        </b-tabs>

        <!--        <ul>-->
        <!--          <li-->
        <!--            v-for="(item, grp_index) in permittedSearchData"-->
        <!--            :key="grp_index"-->
        <!--            class="suggestions-groups-list"-->
        <!--          >-->

        <!--            &lt;!&ndash; Group Header &ndash;&gt;-->
        <!--            <p class="suggestion-group-title">-->
        <!--              <span>-->
        <!--                {{ $t(`${title(item.key)}`) }}-->
        <!--              </span>-->
        <!--            </p>-->

        <!--            &lt;!&ndash; Suggestion List of each group &ndash;&gt;-->
        <!--            <ul>-->
        <!--              <li-->
        <!--                v-for="(suggestion, index) in item.data"-->
        <!--                :key="index"-->
        <!--                class="suggestion-group-suggestion cursor-pointer w-100"-->
        <!--                @mousedown.prevent="suggestionSelected(item.key, suggestion)"-->
        <!--              >-->
        <!--                &lt;!&ndash; Users, Contacts &ndash;&gt;-->
        <!--                <b-link-->
        <!--                  v-if="item.key === 'users' || item.key === 'contacts'"-->
        <!--                  class="p-0 d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between"-->
        <!--                  style="column-gap: .5rem; row-gap: .34rem"-->
        <!--                >-->
        <!--                  <avatar-->
        <!--                    variant="light-primary"-->
        <!--                    :user="suggestion"-->
        <!--                  >-->
        <!--                    <template #subtitle>-->
        <!--                      <div-->
        <!--                        class="d-flex flex-wrap"-->
        <!--                        style="gap: .34rem"-->
        <!--                      >-->
        <!--                        <span v-if="suggestion.email">{{ suggestion.email }}</span>-->
        <!--                        <span v-if="suggestion.email">|</span>-->
        <!--                        <span v-if="suggestion.phone">{{ suggestion.phone | phoneFormat }}</span>-->
        <!--                      </div>-->
        <!--                    </template>-->
        <!--                  </avatar>-->

        <!--                  <div-->
        <!--                    class="d-flex flex-wrap"-->
        <!--                    style="column-gap: .5rem"-->
        <!--                  >-->
        <!--                    <b-badge-->
        <!--                      v-for="(field, i) in suggestion.contactAddonFields"-->
        <!--                      :key="i"-->
        <!--                      v-b-tooltip.hover.v-primary-->
        <!--                      variant="light-primary"-->
        <!--                      :title="field.contactAddonFieldType.name"-->
        <!--                    >-->
        <!--                      <template v-if="field.contactAddonFieldTypeOptions.length">-->
        <!--                        {{ field.contactAddonFieldTypeOptions.map(i => i.name).join(', ') }}-->
        <!--                      </template>-->

        <!--                      <template v-else-if="field.contactAddonFieldTextOptions.length">-->
        <!--                        {{ field.contactAddonFieldTextOptions.join(', ') }}-->
        <!--                      </template>-->

        <!--                      <template v-else>-->
        <!--                        <template-->
        <!--                          v-if="-->
        <!--                            field.value.toLowerCase() === 'true'-->
        <!--                              || field.value.toLowerCase() === 'false'"-->
        <!--                        >-->
        <!--                          {{ field.contactAddonFieldType.name }}-->
        <!--                        </template>-->

        <!--                        <template v-else>-->
        <!--                          {{ field.value }}-->
        <!--                        </template>-->
        <!--                      </template>-->
        <!--                    </b-badge>-->
        <!--                  </div>-->
        <!--                </b-link>-->

        <!--                <b-link-->
        <!--                  v-else-if="item.key === 'orders'"-->
        <!--                  class="p-0 d-flex w-100"-->
        <!--                >-->
        <!--                  <div class="d-flex align-items-center">-->
        <!--                    <b-avatar variant="light-primary">-->
        <!--                      <feather-icon icon="ShoppingBagIcon" />-->
        <!--                    </b-avatar>-->
        <!--                    <div class="ml-50">-->
        <!--                      <div>{{ suggestion.number }}</div>-->
        <!--                      <small class="text-muted font-small-3">{{ getFullName(suggestion.contactThreadCartBox.contactThread.contact) }}</small>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </b-link>-->

        <!--                <b-link-->
        <!--                  v-else-if="item.key === 'organizations'"-->
        <!--                  class="p-0 d-flex flex-column flex-md-row flex-wrap align-items-md-center justify-content-between"-->
        <!--                  style="row-gap: .5rem; column-gap: .34rem"-->
        <!--                >-->
        <!--                  <div-->
        <!--                    class="d-flex flex-column flex-md-row"-->
        <!--                    style="row-gap: .5rem; column-gap: 1rem"-->
        <!--                  >-->
        <!--                    <span class="text-primary">{{ suggestion.name }}</span>-->

        <!--                    <div-->
        <!--                      class="d-flex flex-column flex-md-row"-->
        <!--                      style="gap: .34rem"-->
        <!--                    >-->
        <!--                      {{ $t('Contacts') }}:-->

        <!--                      <b-avatar-group-->
        <!--                        v-if="suggestion.contacts.length"-->
        <!--                        size="sm"-->
        <!--                      >-->
        <!--                        <b-avatar-->
        <!--                          v-for="(contact, i) in suggestion.contacts.slice(0, 3)"-->
        <!--                          :key="i"-->
        <!--                          v-b-tooltip.v-primary-->
        <!--                          variant="light-primary"-->
        <!--                          :user="contact"-->
        <!--                          :title="`${contact.firstName} ${contact.lastName}`"-->
        <!--                        />-->
        <!--                      </b-avatar-group>-->

        <!--                      <span v-else>-->
        <!--                        <feather-icon icon="MinusIcon" />-->
        <!--                      </span>-->
        <!--                    </div>-->

        <!--                    <div-->
        <!--                      class="d-flex flex-column flex-md-row"-->
        <!--                      style="gap: .34rem"-->
        <!--                    >-->
        <!--                      {{ $t('Users') }}:-->

        <!--                      <b-avatar-group-->
        <!--                        v-if="suggestion.users.length"-->
        <!--                        size="sm"-->
        <!--                      >-->
        <!--                        <b-avatar-->
        <!--                          v-for="(user, i) in suggestion.users.slice(0, 3)"-->
        <!--                          :key="i"-->
        <!--                          v-b-tooltip.v-primary-->
        <!--                          variant="light-primary"-->
        <!--                          :user="user"-->
        <!--                          :title="`${user.firstName} ${user.lastName}`"-->
        <!--                        />-->
        <!--                      </b-avatar-group>-->

        <!--                      <span v-else>-->
        <!--                        <feather-icon icon="MinusIcon" />-->
        <!--                      </span>-->
        <!--                    </div>-->
        <!--                  </div>-->

        <!--                  <div-->
        <!--                    v-if="suggestion.vatId"-->
        <!--                    class="d-flex flex-wrap"-->
        <!--                    style="column-gap: .5rem"-->
        <!--                  >-->
        <!--                    <b-badge-->
        <!--                      v-b-tooltip.hover.v-primary-->
        <!--                      variant="light-primary"-->
        <!--                      :title="$t('contact.vatId')"-->
        <!--                    >-->
        <!--                      {{ suggestion.vatId }}-->
        <!--                    </b-badge>-->
        <!--                  </div>-->
        <!--                </b-link>-->
        <!--              </li>-->

        <!--              <li-->
        <!--                v-if="item.loading"-->
        <!--                class="d-flex justify-content-center"-->
        <!--              >-->
        <!--                <b-spinner-->
        <!--                  variant="primary"-->
        <!--                  class="m-1"-->
        <!--                />-->
        <!--              </li>-->

        <!--              <li-->
        <!--                v-else-if="!item.data.length && searchQuery"-->
        <!--                class="suggestion-group-suggestion no-results"-->
        <!--              >-->
        <!--                <p>{{ $t('NoOptions') }}</p>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </li>-->
        <!--        </ul>-->
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import { BFormInput, BLink, VBTooltip } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { title } from '@core/utils/filter'
import store from '@/store'
import { mapGetters } from 'vuex'
import { checkRequiredModule, checkRequiredPermissions } from '@core/mixins'
import {
  CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL,
  CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF,
  CONTACT_THREAD_ORDER_VIEW_ALL,
  CONTACT_VIEW_ALL,
  CONTACT_VIEW_ASSIGNED,
  CONTACT_VIEW_STRUCTURE, APPLICATION_VIEW_ALL, APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD,
} from '@/helpers/permissions'
import { ORGANIZATION_VIEW_ALL } from '@/@constants/mutations'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BFormInput,
    BLink,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  created() {
    window.addEventListener('keyup', this.shortcut)
  },
  destroyed() {
    window.removeEventListener('keyup', this.shortcut)
  },
  computed: {
    ...mapGetters({
      searchData: 'searchBar/getSearchSectionsWithData',
    }),
    permittedSearchData() {
      return this.searchData.filter(dataItem => ((
        (!dataItem?.requiredModule || this.checkRequiredModule(dataItem.requiredModule))
        && (!dataItem?.requiredPermissions?.length || this.checkRequiredPermissions(dataItem.requiredPermissions))
      ) ? dataItem : false)) || []
    },
  },
  methods: {
    suggestionSelected(group, item) {
      if (group === 'applications') {
        if (item.contactThread && item.contactThread?.contact) this.$router.push(`/contact/${item.contactThread?.contact.id}/thread/${item.contactThread?.id}/timeline`)
        else if (this.$route.name !== 'applications') this.$router.push('/applications')
      }
      if (group === 'users') this.$router.push(`/user/${item.id}`)
      if (group === 'contacts') this.$router.push(`/contact/${item.id}`)
      if (group === 'orders') this.$router.push(`/contact/${item.contactThreadCartBox.contactThread.contact.id}/thread/${item.contactThreadCartBox.contactThread.id}/orders`)
      if (group === 'organizations') this.$router.push({ path: `/organizations/${item.id}`, params: { id: item.id } })

      this.resetsearchQuery()
      this.showSearchBar = false
    },
    getName(suggestion) {
      return `${suggestion.firstName ? suggestion.firstName[0].toUpperCase() : ''}${suggestion.lastName ? suggestion.lastName[0].toUpperCase() : ''}`
    },
    getFullName(suggestion) {
      return `${suggestion.firstName || ''} ${suggestion.lastName || ''}`
    },
    shortcut(e) {
      if (e.ctrlKey && e.key === 'Enter') this.showSearchBar = true
    },
  },
  setup() {
    const showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({ data: searchAndBookmarkData, searchLimit: 4 })

    watch(searchQuery, val => {
      const options = []
      if (checkRequiredPermissions([CONTACT_VIEW_ALL, CONTACT_VIEW_ASSIGNED, CONTACT_VIEW_STRUCTURE])) {
        options.push('GET_CONTACTS')
      }

      if (checkRequiredModule('orderModule') && checkRequiredPermissions([CONTACT_THREAD_ORDER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF])) {
        options.push('GET_ORDERS')
      }

      if (checkRequiredPermissions([ORGANIZATION_VIEW_ALL])) {
        options.push('GET_ORGANIZATIONS')
      }

      if (checkRequiredPermissions([APPLICATION_VIEW_ALL, APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD]) && checkRequiredModule('applicationModule')) {
        options.push('GET_APPLICATIONS')
      }

      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
      store.dispatch('searchBar/RUN_SEARCH_QUERY', { search: val, options })
    })

    return {
      showSearchBar,
      perfectScrollbarSettings,
      searchAndBookmarkData,
      title,
      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem;
  &:hover {
    background-color: $body-bg;

    .dark-layout & {
      background-color: $theme-dark-body-bg;
    }
  }
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
