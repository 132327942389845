<!--eslint-disable-->

<template>
  <div>
    <hr
      v-show="product.id > 0"
      class="separator"
    >
    <!--  py-25 - add when spinbutton active -->
    <div class="mx-1 py-75 d-flex justify-content-between flex-wrap">
      <div class="d-flex flex-column align-items-start flex-md-row w-100 ml-25">
        <!--    Cart Items Product title    -->
        <div class="d-flex align-items-center mb-md-25 pb-md-0 pl-0 flex-grow-1">
          <div
            class="font-weight-bold flex-grow-1 d-flex align-items-center"
            style="max-width: 250px"
          >
            <b-badge
              v-if="product.shopProduct.shopProductDiscountsActive.length"
              v-b-tooltip
              variant="light-success"
              :title="$t('ProductOnDiscount')"
            >
              <feather-icon icon="DollarSignIcon" />
            </b-badge>

            <router-link
              v-b-tooltip.hover
              :to="{ name: 'productView', params: { productId: product.shopProduct.id } }"
              class="text-primary w-100"
              :title="(product.shopProduct.translations[0] ? product.shopProduct.translations[0].name : '–')"
            >
              {{ (product.shopProduct.translations[0] ? product.shopProduct.translations[0].name : '–') | truncate(30, '…') }}
            </router-link>
          </div>
        </div>

        <!--    Cart Items Product price    -->
        <div
          class="d-flex align-items-center justify-content-end mt-25 mt-md-0"
        >
          <span
            v-b-tooltip.hover.left.html
            class="font-weight-bold"
            :title="pricesTooltip"
          >
            {{ grossPrice(product) | priceGrossFormat }} {{ $store.getters['system/getSettings'] ? ($store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN' }}
          </span>
        </div>
      </div>

      <!-- Count -->
      <div class="d-flex align-items-center justify-content-between ml-25 w-100">
        <div>
          {{ $t('Offer.Amount') }}

          <template v-if="product.shopProduct && product.shopProduct.amountUnitType">
            ({{ $t(`unit.${product.shopProduct.amountUnitType}`) }})
          </template>
        </div>

        <b-form-spinbutton
          v-model="product.amount"
          size="sm"
          class="cart-custom-spin mb-50 mb-md-0 button"
          style="width: 150px"
          min="1"
          :max="product.shopProduct.shopProductStoragesSum"
          @input="onChangeAmount"
        />

      </div>
    </div>

    <!--    Cart Items Product Delete    -->
    <b-button
      variant="undefined"
      class="
        btn-icon
        rounded-circle
        px-25
        py-25
        position-absolute
        deleteFromCart
        d-md-none
      "
      @click="$emit('remove-product', product.id)"
    >
      <feather-icon
        size="16"
        icon="XIcon"
      />
    </b-button>
  </div>
</template>

<script>
import { BFormSpinbutton, VBTooltip } from 'bootstrap-vue'
import { CHANGE_PRODUCT_AMOUNT } from '@/@constants/mutations'
import { grossPrice, netPriceAfterCartDiscount, netPriceByAmount } from '@/helpers/cartProductPrices'
// eslint-disable-next-line no-unused-vars
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

export default {
  components: {
    // BImg,
    BFormSpinbutton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    gross: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      required: true,
    },
    isOnOverwriteVatInCart: {
      type: Boolean,
      required: false,
      default: false,
    },
    overwriteVatInCart: {
      required: false,
      default: 0,
    },
  },
  emits: ['remove', 'changeQuantity'],
  data: () => ({
    productVat: 0,
  }),
  methods: {
    netPriceByAmount,
    netPriceAfterCartDiscount,
    grossPrice,
    assignOverwriteVatInCart(value = this.overwriteVatInCart) {
      if (value !== null) this.productVat = value
      else if (this.product.overwriteVat !== null) this.productVat = this.product.overwriteVat
      else this.productVat = this.product.shopProduct.vat ?? 23
    },
    onChangeAmount() {
      this.$nextTick(() => {
        this.$store.dispatch(`cart/${CHANGE_PRODUCT_AMOUNT}`, this.product)
      })
    },
    priceFormat(value) {
      return this.$options.filters.priceFormat(value)
    },
    priceGrossFormat(value) {
      return this.$options.filters.priceGrossFormat(value)
    },
    pricesTooltip() {
      return `
        <div>
          <div class="text-nowrap my-25">
            ${this.$i18n.t('offer.GrossPrice')}
            <div class="font-weight-bolder"> ${this.priceGrossFormat(this.grossPrice(this.product))} ${this.$store.getters['system/getSettings'] ? (this.$store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN'}</div>
          </div>
          <div class="text-nowrap my-25">
            ${this.$i18n.t('offer.NettoPrice')}
            <div class="font-weight-bolder"> ${this.priceFormat(this.netPriceAfterCartDiscount(this.product))} ${this.$store.getters['system/getSettings'] ? (this.$store.getters['system/getSettings'].defaultCurrency || 'PLN') : 'PLN'}</div>
          </div>
        </div>
      `
    },
  },
}
</script>

<style lang="scss">
.doubleLineText {
  width: 9rem !important;

  display: inline-block;

  overflow-wrap: anywhere;

  white-space: normal;
}

.cart-custom-spinbutton {
  border: unset !important;
  .border-bottom, .border-top {
    border: unset !important;
  }
  output {
    background-color: rgba(115, 103, 240, 0.12);
    color: #7367f0 !important;
    border-radius: 0.358rem;
  }
}
</style>
