<template>
  <div>
    <div
      class="main-menu menu-fixed menu-accordion menu-shadow overflow-hidden"
      style="overflow: visible"
      :class="[
        { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
      ]"
    >
      <!--    @mouseenter="updateMouseHovered(true)"-->
      <!--    @mouseleave="updateMouseHovered(false)"-->
      <!-- main menu header-->
      <div class="navbar-header expanded">
        <slot
          name="header"
          :toggle-vertical-menu-active="toggleVerticalMenuActive"
          :toggle-collapsed="toggleCollapsed"
          :collapse-toggler-icon="collapseTogglerIcon"
        >
          <ul class="nav navbar-nav flex-row">

            <!-- Logo & Text -->
            <li class="nav-item mr-auto">
              <b-link
                class="navbar-brand"
                to="/"
              >
                <span class="brand-logo">
                  <!--                    style="max-width: 175px; max-height: 65px; width: 100%; object-fit: cover"-->
                  <b-img
                    :src="!isVerticalMenuCollapsed ? system.logo : system.signet"
                    class="logo"
                    :class="{ 'app-logo': (!isVerticalMenuCollapsed), 'app-signet': (isVerticalMenuCollapsed) }"
                  />
                </span>
              <!--              <h2 class="brand-text">-->
              <!--                {{ appName }}-->
              <!--              </h2>-->
              </b-link>
            </li>

          <!-- Toggler Button -->
          <!--          <li class="nav-item nav-toggle">-->
          <!--            <b-link class="nav-link modern-nav-toggle">-->
          <!--              <feather-icon-->
          <!--                icon="XIcon"-->
          <!--                size="20"-->
          <!--                class="d-block d-xl-none"-->
          <!--                @click="toggleVerticalMenuActive"-->
          <!--              />-->
          <!--              <feather-icon-->
          <!--                :icon="collapseTogglerIconFeather"-->
          <!--                size="20"-->
          <!--                class="d-none d-xl-block collapse-toggle-icon"-->
          <!--                @click="toggleCollapsed"-->
          <!--              />-->
          <!--            </b-link>-->
          <!--          </li>-->
          </ul>
        </slot>
      </div>
      <!-- / main menu header-->

      <!-- Shadow -->
      <div
        :class="{'d-block': shallShadowBottom}"
        class="shadow-bottom"
      />

      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <vertical-nav-menu-items
          key="vertical-nav-menu-items"
          :nav="nav()"
          class="navigation navigation-main"
          @click="group => { navSubMenuItems = !navSubMenuItems.length ? group : [] }"
        />
      </vue-perfect-scrollbar>
      <!-- /main menu content-->
    </div>

    <transition name="slide-left">
      <vue-perfect-scrollbar
        v-if="isSwGroup && navSubMenuItems.length"
        id="navSubMenu"
        :settings="perfectScrollbarSettings"
        class="vertical-nav-menu-more shadow-lg scroll-area"
        :class="{ 'vertical-nav-menu-more-collapsed': !isVerticalMenuCollapsed }"
      >
        <b-list-group flush>
          <vertical-nav-menu-link
            v-for="child in navSubMenuItems"
            :key="child.title"
            :item="child"
            container="b-list-group-item"
            @click="clearNavSubMenuItems()"
          />
        </b-list-group>
      </vue-perfect-scrollbar>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */

// import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BImg, BLink } from 'bootstrap-vue'
import { computed, provide, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { GET_SETTINGS } from '@/@constants/mutations'
// import axiosIns from '@/libs/axios'
import Logo from '@/assets/images/core/logo.png'
import Signet from '@/assets/images/core/sygnet.png'
import { mapGetters } from 'vuex'
import VerticalNavMenuLink
  from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VerticalNavMenuLink,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    navMenuItems: [],
    navSubMenuItems: [],
    appLogo: Logo,
    appSignet: Signet,
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      currentUser: 'auth/getCurrentUser',
      nav: ('verticalMenu/itemsFull'),
    }),
  },
  async created() {
    await this.$store.dispatch(`system/${GET_SETTINGS}`)
    //   .then(() => {
    //     // eslint-disable-next-line array-callback-return
    //     const preparedNavItems = navMenuItems.filter(route => this.routeFilter(route)).map(route => {
    //       const routeItem = route
    //       if (routeItem?.children?.length) {
    //         routeItem.children = routeItem.children.filter(childRoute => this.routeFilter(childRoute)).map(child => child)
    //         routeItem.children.forEach(k => {
    //           if (k?.children?.length) {
    //             // eslint-disable-next-line
    //             k.children = k.children.filter(childRoute => this.routeFilter(childRoute))
    //               .map(child => child)
    //           }
    //         })
    //       }
    //
    //       return routeItem
    //     })
    //
    //     const token = localStorage.getItem('token')
    //     if (token && this.findFunnelIndex(preparedNavItems) >= 0) {
    //       axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
    //       this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
    //         .then(res => {
    //           res.forEach((funnel, index) => {
    //             preparedNavItems[this.findFunnelIndex(preparedNavItems)].children[index] = {
    //               title: funnel.name,
    //               route: { name: 'sales-funnel', params: { id: funnel.id } },
    //             }
    //           })
    //         })
    //         .finally(() => {
    //           this.$set(this, 'navMenuItems', preparedNavItems)
    //         })
    //     } else {
    //       this.$set(this, 'navMenuItems', preparedNavItems)
    //     }
    //   })
  },
  setup(props) {
    const {
      isSwGroup,
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      isSwGroup,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  mounted() {
    // console.log(this.nav())

    document.addEventListener('click', this.clickOutsideEvent)
  },
  methods: {
    findFunnelIndex(array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[i].route === 'sales-funnel') {
          return i
        }
      }
      return -1
    },

    clearNavSubMenuItems() { this.navSubMenuItems = [] },

    clickOutsideEvent(e) {
      const el = document.querySelector('#navSubMenu')
      const button = document.querySelector('#root_menu_item_999_more')

      if (!el || !(el === e.target || el.contains(e.target) || button === e.target || button.contains(e.target))) this.clearNavSubMenuItems()
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutsideEvent)
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.app-logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 125px !important;
}
.app-signet {
  margin-top: -4px;
  width: 36px !important;
}

.vertical-nav-menu-more {
  position: fixed;
  height: calc(100% - 4rem);
  bottom : 0;
  left: 3.5rem;
  //padding: .5rem 0;
  //padding: 1.5rem 1rem;
  background: rgba(238, 238, 240);
  z-index: 1030;
  .list-group {
    width: 100vw;
    max-width: 14rem;
  }
}

.vertical-nav-menu-more-collapsed {
  left: var(--theme-width-menu) !important;
  height: calc(100%);
}

.slide-left-enter-active {
  animation: bounce-in ease-in-out 0.14s;
}

.slide-left-leave-active {
  animation: bounce-in ease-in-out 0.14s reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translateX(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
