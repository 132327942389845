<!--<template>-->
<!--  <b-row-->
<!--    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"-->
<!--    class="content-header"-->
<!--  >-->

<!--    &lt;!&ndash; Content Left &ndash;&gt;-->
<!--    <b-col-->
<!--      class="content-header-left mb-2"-->
<!--      cols="12"-->
<!--      md="9"-->
<!--    >-->
<!--      <b-row class="breadcrumbs-top">-->
<!--        <b-col cols="12">-->
<!--          <h2 class="content-header-title float-left pr-1 mb-0">-->
<!--            {{ $t($route.meta.pageTitle) }}-->
<!--          </h2>-->
<!--          <div class="breadcrumb-wrapper">-->
<!--            <b-breadcrumb>-->
<!--              <b-breadcrumb-item to="/">-->
<!--                <feather-icon-->
<!--                  icon="HomeIcon"-->
<!--                  size="16"-->
<!--                  class="align-text-top"-->
<!--                />-->
<!--              </b-breadcrumb-item>-->
<!--              <b-breadcrumb-item-->
<!--                v-for="item in $route.meta.breadcrumb"-->
<!--                :key="item.text"-->
<!--                :active="item.active"-->
<!--                :to="item.to"-->
<!--              >-->
<!--                {{ checkBreadcrumb(item) || '' }}-->
<!--                &lt;!&ndash;                {{ !item.notTranslatable ? $t(item.text) : item.text }}&ndash;&gt;-->
<!--              </b-breadcrumb-item>-->
<!--            </b-breadcrumb>-->
<!--          </div>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--    </b-col>-->

<!--    &lt;!&ndash; Content Right &ndash;&gt;-->
<!--    &lt;!&ndash;    <b-col&ndash;&gt;-->
<!--    &lt;!&ndash;      class="content-header-right text-md-right d-md-block d-none mb-1"&ndash;&gt;-->
<!--    &lt;!&ndash;      md="3"&ndash;&gt;-->
<!--    &lt;!&ndash;      cols="12"&ndash;&gt;-->
<!--    &lt;!&ndash;    >&ndash;&gt;-->
<!--    &lt;!&ndash;      <b-dropdown&ndash;&gt;-->
<!--    &lt;!&ndash;        variant="link"&ndash;&gt;-->
<!--    &lt;!&ndash;        no-caret&ndash;&gt;-->
<!--    &lt;!&ndash;        toggle-class="p-0"&ndash;&gt;-->
<!--    &lt;!&ndash;        right&ndash;&gt;-->
<!--    &lt;!&ndash;      >&ndash;&gt;-->

<!--    &lt;!&ndash;        <template #button-content>&ndash;&gt;-->
<!--    &lt;!&ndash;          <b-button&ndash;&gt;-->
<!--    &lt;!&ndash;            v-ripple.400="'rgba(255, 255, 255, 0.15)'"&ndash;&gt;-->
<!--    &lt;!&ndash;            variant="primary"&ndash;&gt;-->
<!--    &lt;!&ndash;            class="btn-icon"&ndash;&gt;-->
<!--    &lt;!&ndash;          >&ndash;&gt;-->
<!--    &lt;!&ndash;            <feather-icon icon="SettingsIcon" />&ndash;&gt;-->
<!--    &lt;!&ndash;          </b-button>&ndash;&gt;-->
<!--    &lt;!&ndash;        </template>&ndash;&gt;-->

<!--    &lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-todo' }">&ndash;&gt;-->
<!--    &lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--    &lt;!&ndash;            icon="CheckSquareIcon"&ndash;&gt;-->
<!--    &lt;!&ndash;            size="16"&ndash;&gt;-->
<!--    &lt;!&ndash;          />&ndash;&gt;-->
<!--    &lt;!&ndash;          <span class="align-middle ml-50">Todo</span>&ndash;&gt;-->
<!--    &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->

<!--    &lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-chat' }">&ndash;&gt;-->
<!--    &lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--    &lt;!&ndash;            icon="MessageSquareIcon"&ndash;&gt;-->
<!--    &lt;!&ndash;            size="16"&ndash;&gt;-->
<!--    &lt;!&ndash;          />&ndash;&gt;-->
<!--    &lt;!&ndash;          <span class="align-middle ml-50">Chat</span>&ndash;&gt;-->
<!--    &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->

<!--    &lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-email' }">&ndash;&gt;-->
<!--    &lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--    &lt;!&ndash;            icon="MailIcon"&ndash;&gt;-->
<!--    &lt;!&ndash;            size="16"&ndash;&gt;-->
<!--    &lt;!&ndash;          />&ndash;&gt;-->
<!--    &lt;!&ndash;          <span class="align-middle ml-50">Email</span>&ndash;&gt;-->
<!--    &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->

<!--    &lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-gloablCalendar' }">&ndash;&gt;-->
<!--    &lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--    &lt;!&ndash;            icon="CalendarIcon"&ndash;&gt;-->
<!--    &lt;!&ndash;            size="16"&ndash;&gt;-->
<!--    &lt;!&ndash;          />&ndash;&gt;-->
<!--    &lt;!&ndash;          <span class="align-middle ml-50">Calendar</span>&ndash;&gt;-->
<!--    &lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->
<!--    &lt;!&ndash;      </b-dropdown>&ndash;&gt;-->
<!--    &lt;!&ndash;    </b-col>&ndash;&gt;-->
<!--  </b-row>-->
<!--</template>-->

<!--<script>-->
<!--import {-->
<!--  BBreadcrumb, BBreadcrumbItem, BRow, BCol,-->
<!--} from 'bootstrap-vue'-->
<!--import Ripple from 'vue-ripple-directive'-->

<!--export default {-->
<!--  directives: {-->
<!--    Ripple,-->
<!--  },-->
<!--  components: {-->
<!--    BBreadcrumb,-->
<!--    BBreadcrumbItem,-->
<!--    BRow,-->
<!--    BCol,-->
<!--  },-->
<!--  methods: {-->
<!--    checkBreadcrumb(item) {-->
<!--      let displayedText = ''-->
<!--      if (item.paramToText) {-->
<!--        const id = this.$route.params[item.paramToText]-->
<!--        if (item.paramToText === 'contactId') {-->
<!--          const contact = this.$store.getters['singleContact/getContact']-->
<!--          if (id && contact && contact.firstName) {-->
<!--            displayedText = `${contact.firstName} ${contact.lastName}`-->
<!--            this.$store.dispatch('app/UPDATE_TITLE', {-->
<!--              label: `${this.$i18n.t('Contact')} | ${displayedText}`,-->
<!--              title: 'Contact',-->
<!--            })-->
<!--          }-->
<!--        }-->
<!--        if (item.paramToText === 'userId') {-->
<!--          const user = this.$store.getters['users/getSingleUser']-->
<!--          if (id && user && user.firstName) {-->
<!--            displayedText = `${user.firstName} ${user.lastName}`-->
<!--            this.$store.dispatch('app/UPDATE_TITLE', {-->
<!--              label: `${this.$i18n.t('User')} | ${displayedText}`,-->
<!--              title: 'User',-->
<!--            })-->
<!--          }-->
<!--        }-->
<!--      } else {-->
<!--        displayedText = !item.notTranslatable ? this.$i18n.t(item.text) : item.text-->
<!--      }-->
<!--      return displayedText-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->

<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <component
                :is="$route.path !== item.to ? 'b-breadcrumb-item' : 'div'"
                v-for="item in breadcrumbs"
                :key="item.text"
                :active="$route.path !== item.to && item.active"
                :to="getRedirect(item)"
              >
                <span v-if="$route.path !== item.to">
                  <b-spinner
                    v-if="item.text === 'loading'"
                    type="grow"
                    small
                    class="ml-50"
                    variant="primary"
                  />
                  <span v-else v-html="!item.notTranslatable ? $t(item.text) : item.text">
                  </span>
                </span>
              </component>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <!--    <b-col-->
    <!--      class="content-header-right text-md-right d-md-block d-none mb-1"-->
    <!--      md="3"-->
    <!--      cols="12"-->
    <!--    >-->
    <!--      <b-dropdown-->
    <!--        variant="link"-->
    <!--        no-caret-->
    <!--        toggle-class="p-0"-->
    <!--        right-->
    <!--      >-->

    <!--        <template #button-content>-->
    <!--          <b-button-->
    <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--            variant="primary"-->
    <!--            class="btn-icon"-->
    <!--          >-->
    <!--            <feather-icon icon="SettingsIcon" />-->
    <!--          </b-button>-->
    <!--        </template>-->

    <!--        <b-dropdown-item :to="{ name: 'apps-todo' }">-->
    <!--          <feather-icon-->
    <!--            icon="CheckSquareIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--          <span class="align-middle ml-50">Todo</span>-->
    <!--        </b-dropdown-item>-->

    <!--        <b-dropdown-item :to="{ name: 'apps-chat' }">-->
    <!--          <feather-icon-->
    <!--            icon="MessageSquareIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--          <span class="align-middle ml-50">Chat</span>-->
    <!--        </b-dropdown-item>-->

    <!--        <b-dropdown-item :to="{ name: 'apps-email' }">-->
    <!--          <feather-icon-->
    <!--            icon="MailIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--          <span class="align-middle ml-50">Email</span>-->
    <!--        </b-dropdown-item>-->

    <!--        <b-dropdown-item :to="{ name: 'apps-gloablCalendar' }">-->
    <!--          <feather-icon-->
    <!--            icon="CalendarIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--          <span class="align-middle ml-50">Calendar</span>-->
    <!--        </b-dropdown-item>-->
    <!--      </b-dropdown>-->
    <!--    </b-col>-->
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  data: () => ({
    breadcrumbs: [],
    loading: false,
  }),
  watch: {
    $route: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.path !== oldValue.path) this.prepareBreadcrumbs(newValue.meta.breadcrumb)
      },
    },
  },
  mounted() {
    this.prepareBreadcrumbs()
  },
  methods: {
    prepareBreadcrumbs(items = this.$route.meta.breadcrumb) {
      this.loading = true
      this.breadcrumbs = []

      if (Array.isArray(items) && items.length) {
        this.breadcrumbs = items
        this.breadcrumbs.forEach((breadcrumb, index) => {
          if (breadcrumb.request) this.checkBreadcrumb(breadcrumb, index)
        })
      }
    },
    async checkBreadcrumb(item, index) {
      this.$set(this.breadcrumbs[index], 'text', 'loading')
      let displayedText = ''
      const param = item.request.routeParam
      const id = this.$route.params[param]
      const url = item.request.endpoint.replaceAll(`{${param}}`, id)

      const { data } = await axiosIns.get(`1${url}`, { params: { fields_load: item.request.fields_load } })
      setTimeout(() => {
        if (data?.data?.item) {
          item.request.fields.forEach(field => {
            if (field.includes('translations')) {
              // eslint-disable-next-line no-unused-vars
              const [t, fieldName] = field.split('.')
              const { translations } = data.data.item
              if (Array.isArray(translations) && translations.length) displayedText += `${translations[0][fieldName]} `
            } else if (data.data.item[field]) displayedText += `${data.data.item[field]} `
          })
        }

        this.$set(this.breadcrumbs[index], 'text', displayedText)
      }, 400)

      // return displayedText
    },
    getRedirect(item) {
      if (!item.active && item.request) {
        const param = item.request.routeParam
        const id = this.$route.params[param]
        return item.request.to.replaceAll(`{${param}}`, id)
      }

      return item.to
    },
  },
}
</script>
