<template>
  <component
    :is="container && container.length ? container : 'li'"
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item p-0 position-relative"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
    button
    @click="e => $emit('click', e)"
    @mouseover="e => $emit('mouseover', e)"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center text-dark position-relative"
      :class="{ 'px-1 py-75': container }"
      style="column-gap: .34rem"
    >
      <sw-icon
        v-if="item.icon"
        :icon="item.icon || 'CircleIcon'"
        :style="!item.icon ? 'width: 8px; height: 8px;' : ''"
      />
      <span class="menu-title text-truncate">{{ $t(item.title) }}</span>
    </b-link>

    <b-badge
      v-if="getTags()"
      pill
      size="sm"
      small
      :variant="'primary'"
      style="position: absolute; bottom: -3px; right: -3px; width: auto; font-size: .6rem; color: white !important"
    >
      {{ getTags() }}
    </b-badge>
  </component>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BBadge, BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { mapGetters } from 'vuex'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    container: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      globalInfo: 'verticalMenu/globalInfo',
    }),
  },
  emits: ['click', 'mouseover'],
  methods: {
    getTags() {
      if (this.item.key === 'chat') {
        return this.globalInfo.unreadChatConversations
      }

      if (this.item.key === 'client-chat') {
        return this.globalInfo.unreadClientChats
      }

      return 0
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    // console.log('->', props.item.key, props.item.title, props.item.route)

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  // watch: {
  //   isActive: {
  //     deep: true,
  //     handler() {
  //       if (this.item.title === 'Chat') {
  //         this.$set(this.item, 'tag', 0)
  //       }
  //     },
  //   },
  // },
}
</script>
